import React from "react";
import { Navigate } from "react-router-dom";

interface AuthRouteProps {
  element: React.ReactNode;
}

function AuthRoute({ element }: AuthRouteProps) {
  if (localStorage.getItem("token")) {
    return <>{element}</>;
  } else {
    return <Navigate to="/login" />;
  }
}
export default AuthRoute;