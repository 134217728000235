import React, { useState, useEffect } from "react";
import { client } from "../services/menu.service";
import { Navigate } from "react-router-dom";

import { message } from "antd";
import { Flex } from "antd";
import { Avatar, Button, Input, Form, FormProps } from "antd";

type FieldType = {
  new_password1?: string;
  new_password2?: string;
};

function PWchanger() {
  const [currentUser, setCurrentUser] = useState(true);
  const [resetStatus, setResetStatus] = useState(false);
  const [avatarURL, setAvatarURL] = useState(
    "https://api.dicebear.com/7.x/miniavs/svg?seed=45"
  );
  useEffect(() => {
    client
      .get("/rest-auth/user")
      .then(function (res) {
        if (res.data.username) {
          setCurrentUser(res.data.first_name);
          client.get(`/users?id=${res.data.username}`).then(function (res2) {
            setAvatarURL(res2.data[0].src);
          });
        } else {
          setCurrentUser(false);
        }
      })
      .catch(function (error) {
        setCurrentUser(false);
      });
  }, []);

  const onFinish: FormProps<FieldType>["onFinish"] = (values) => {
    if (values.new_password1 !== values.new_password2) {
      message.error("Password & Confirm Password do not match!");
    } else {
      client
        .post("/rest-auth/password/change/", values)
        .then(function (res) {
          setResetStatus(true);
        })
        .catch(function (error) {
          message.error("Invalid password. Please try again!");
        });
    }
  };

  let f = (
    <Form
      name="basic"
      labelAlign="left"
      labelCol={{ span: 12 }}
      wrapperCol={{ span: 12 }}
      initialValues={{ remember: true }}
      onFinish={onFinish}
      autoComplete="off"
      style={{
        width: 380,
      }}
    >
      <Form.Item<FieldType>
        label="New Password"
        name="new_password1"
        style={{ marginBottom: 10 }}
        rules={[{ required: true, message: "Please enter your username!" }]}
      >
        <Input.Password />
      </Form.Item>

      <Form.Item<FieldType>
        label="Confirm New Password"
        name="new_password2"
        rules={[{ required: true, message: "Please enter your password!" }]}
      >
        <Input.Password />
      </Form.Item>
      <Form.Item wrapperCol={{ span: 24 }} style={{ textAlign: "start" }}>
        <ul
          style={{
            marginBlockStart: 0,
            paddingInlineStart: 12,
            color: "#999",
          }}
        >
          <li>The password must contain at least 8 characters.</li>
          <li>The password can't be entirely numeric.</li>
        </ul>
      </Form.Item>
      <Form.Item wrapperCol={{ span: 24 }}>
        <Flex justify="end">
          <Button type="primary" htmlType="submit">
            Update Password
          </Button>
        </Flex>
      </Form.Item>
    </Form>
  );

  let msg = <span>You are logged in as {currentUser}</span>;

  if (resetStatus) {
    f = <div></div>;
    msg = <span>Your password has been reset successfully.</span>;
  }

  if (!currentUser) {
    return <Navigate to="/login" />;
  } else {
    return (
      <div>
        <p style={{ fontSize: 13, marginBottom: 18, fontWeight: 500 }}>
          <Avatar
            src={avatarURL}
            style={{
              boxShadow: "0 0 0 1px #1f232826",
              marginRight: 6,
              width: 50,
              height: 50,
            }}
          />
          {msg}
        </p>
        {f}
      </div>
    );
  }
}

export default PWchanger;
