import React from "react";
import "./App.css";

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import AuthRoute from "./components/AuthRoute";
import publicRoutes from "./routes/publicRoutes";
import Home from "./Home";
import PWchanger from "./auth/resetpw";
import Topic from "./contents/topics";
import Completion from "./contents/completion";
import DDPage from "./contents/dd";
import Monitor from "./contents/monitor";
import Notfound from "./contents/notfound";

import { ConfigProvider, Layout } from "antd";
const { Content, Footer } = Layout;

function App() {
  return (
    <ConfigProvider
      theme={{
        token: {
          fontSize: 12,
          sizeStep: 3,
          sizeUnit: 4,
          colorPrimary: "#ff1676",
          colorInfo: "#3f51b5",
          colorSuccess: "#30d496",
          colorError: "#f43426",
          colorWarning: "#DC4A3F",
          wireframe: false,
        },
        components: {
          Table: {
            cellPaddingInlineSM: 12,
            cellPaddingInlineMD: 12
          },
        }
      }}
    >
      <Layout className="App">
        <Content className="App-content">
          <Router>
            <Routes>
              {publicRoutes.map(({ path, element, ...routes }) => (
                <Route key={path} path={path} element={element} {...routes} />
              ))}
              <Route key="home" path="/" element={<Home />}>
                <Route index element={<Topic />} />
                <Route path="/topics" element={<Topic />} />
                <Route path="/progress" element={<Completion />} />
                <Route path="/progress/:set" element={<Completion/>}></Route>
                <Route path="/dd/:id" element={<DDPage/>}></Route>
                <Route path="/self-harm-monitor" element={<Monitor/>}></Route>
              </Route>
              <Route
                key="pwchanger"
                path="/changepassword"
                element={<AuthRoute element={<PWchanger />} />}
              />
              <Route
                key="notfound"
                path="*"
                element={<Notfound />}
              />
            </Routes>
          </Router>
        </Content>
        <Footer
          style={{
            padding: "10px 0px",
            backgroundColor: "#f8ebee",
            textAlign: "center",
          }}
        >
          Built with ♥ by SICS Data Management Team
        </Footer>
      </Layout>
    </ConfigProvider>
  );
}

export default App;
