import React, { useEffect, useState, useRef } from "react";
import { client } from "../services/menu.service";
import type { FilterDropdownProps } from "antd/es/table/interface";

import { Typography } from "antd";
import { Table, Progress, Input, Space, Button } from "antd";
import type { TableProps, InputRef, TableColumnType } from "antd";
import { SearchOutlined } from "@ant-design/icons";

const { Title, Text } = Typography;

interface DataType {
  key: string;
  name: string;
  tags: string[];
  code: string;
  universe: number;
  qset: number;
  description: string;
  label: string;
  completed_pert: number;
  completed: number;
}

type DataIndex = keyof DataType;

function Topic() {
  const [topics, setTopics] = useState([]);
  const [loading, setLoading] = useState(true);
  const [subjectcount, setSubjectcount] = useState(0);
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const [lastupdate, setLastupdate] = useState("0000-00-00 00:00:00");
  const searchInput = useRef<InputRef>(null);

  useEffect(() => {

    client
    .get("/last_updated")
    .then(function (res) {
      setLastupdate(res.data[0].last_updated);
    })

    client.get("/qset_count?id=3").then(function (res) {
      setSubjectcount(res.data[0].subject_count);
    });

    client
      .get("/topics")
      .then(function (res) {
        setTopics(res.data);
        setLoading(false);
      })
      .catch(function (error) {
        setTopics([]);
        setLoading(false);
      });
  }, []);

  const handleReset = (clearFilters: () => void) => {
    clearFilters();
    setSearchText("");
  };

  const handleSearch = (
    selectedKeys: string[],
    confirm: FilterDropdownProps["confirm"],
    dataIndex: DataIndex
  ) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const getColumnSearchProps = (
    dataIndex: DataIndex,
    text: string
  ): TableColumnType<DataType> => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            handleSearch(selectedKeys as string[], confirm, dataIndex)
          }
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() =>
              handleSearch(selectedKeys as string[], confirm, dataIndex)
            }
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: boolean) => (
      <SearchOutlined style={{ color: filtered ? "#1677ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes((value as string).toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
  });

  const topicCols: TableProps<DataType>["columns"] = [
    {
      title: "ID",
      dataIndex: "display_id",
      key: "display_id",
      align: "center",
    },
    {
      title: "Questionnaire Set",
      dataIndex: "qset_text",
      key: "qset_text",
      align: "center",
      filters: [
        {
          text: "Set A",
          value: 0,
        },
        {
          text: "Set B",
          value: 1,
        },
      ],
      onFilter: (value, record) => record.qset === value,
    },
    {
      title: "Label",
      dataIndex: "label",
      key: "label",
      align: "center",
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      align: "left",
      render: function (d, record) {
        var link = "dd/" + record.code;
        return <a href={link}>{d}</a>;
      },
      ...getColumnSearchProps("description", "questionnaires"),
    },
    {
      title: "Completion Status",
      dataIndex: "completed_pert",
      key: "completed_pert",
      align: "left",
      render: function (_, record) {
        if (record.completed_pert < 100) {
          return (
            <Progress
              percent={record.completed_pert}
              size={[130, 8]}
              format={() =>
                `${record.completed} (${Math.round(record.completed_pert)}%)`
              }
              strokeColor={"#ff0472"}
            />
          );
        } else {
          return <Progress percent={100} size={[130, 8]} />;
        }
      },
    },
  ];

  return (
    <div>
      <Title level={2} style={{ marginBottom: 2 }}>
        List of Questionnaires
      </Title>
      <Text style={{ color: "#808080" }}>Last Updated: {lastupdate}</Text>
      <Title level={5} style={{ marginBottom: 24 }}>
        <strong>Number of Subjects: </strong>
        {subjectcount} (Set A + Set B)
      </Title>
      <Table
        columns={topicCols}
        dataSource={topics}
        pagination={{ defaultPageSize: 50, hideOnSinglePage: true }}
        loading={{
          spinning: loading,
          tip: "Loading...",
          wrapperClassName: "table-spin",
        }}
      />
    </div>
  );
}

export default Topic;
