import React, { useEffect, useState, useRef } from "react";
import { Link, useParams } from "react-router-dom";
import { client } from "../services/menu.service";
import type { FilterDropdownProps } from "antd/es/table/interface";

import { WarningFilled, SearchOutlined } from "@ant-design/icons";
import { Typography, Progress, Table, Input, Space, Button } from "antd";
import type { TableProps, InputRef, TableColumnType } from "antd";
const { Title } = Typography;

interface DataType {
  description: string;
  eid: string;
  categories: string;
  completed_pert: number;
  unit: string;
}

type DataIndex = keyof DataType;

function DDPage() {
  let params = useParams();
  const [topic, setTopic] = useState<any>("");
  const [dd, setDD] = useState<any>([]);
  const [compl, setCompl] = useState<any>([]);
  const [loading, setLoading] = useState(true);
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef<InputRef>(null);

  const handleReset = (clearFilters: () => void) => {
    clearFilters();
    setSearchText("");
  };

  const handleSearch = (
    selectedKeys: string[],
    confirm: FilterDropdownProps["confirm"],
    dataIndex: DataIndex
  ) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const getColumnSearchProps = (
    dataIndex: DataIndex,
    text: string
  ): TableColumnType<DataType> => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            handleSearch(selectedKeys as string[], confirm, dataIndex)
          }
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() =>
              handleSearch(selectedKeys as string[], confirm, dataIndex)
            }
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: boolean) => (
      <SearchOutlined style={{ color: filtered ? "#1677ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes((value as string).toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
  });


  useEffect(() => {
    if (params.id) {
      client
        .get(`/topics?code=${params.id}`)
        .then(function (res) {
          if (res.data.length === 1) {
            setTopic(res.data[0]);
            return res.data[0];
          }
        })
        .then(function (res) {
          if (res) {
            client
              .get(
                `/variables?topic=${params.id}&query={extag,description,categories,completed,completed_pert,unit}`
              )
              .then(function (res) {
                setDD(res.data);
                setLoading(false);
              });
          }
        });
      
    }
  }, []);

  if (topic) {
    const pg =
      topic.completed / topic.universe == 1 ? (
        <Progress percent={100} size={[200, 8]} />
      ) : (
        <Progress
          percent={topic.completed_pert}
          format={() =>
            `${topic.completed}/${topic.universe} (${topic.completed_pert}%)`
          }
          size={[200, 8]}
          strokeColor={"#ff0472"}
        />
      );

    const ddCols: TableProps<DataType>["columns"] = [
      {
        title: "Data Export Tag",
        dataIndex: "extag",
        key: "extag",
        align: "left",
      },
      {
        title: "Description",
        dataIndex: "description",
        key: "description",
        align: "left",
        ...getColumnSearchProps("description", "description"),
      },
      {
        title: "Choices",
        dataIndex: "categories",
        key: "categories",
        align: "left",
        ...getColumnSearchProps("categories", "choices"),
      },
      {
        title: "Unit",
        dataIndex: "unit",
        key: "unit",
        align: "center",
        width: 50,
        ...getColumnSearchProps("unit", "unit"),
      },
      {
        title: "Answered",
        dataIndex: "completed",
        key: "completed",
        align: "center",
      },
      {
        title: "Completion Rate",
        dataIndex: "completed_pert",
        key: "completed_pert",
        align: "left",
        render: (value, record) => {
          if (record.completed_pert == 100) {
            return <Progress percent={100} size={[125, 8]} />;
          } else {
            return (
              <Progress
                percent={record.completed_pert}
                size={[125, 8]}
                strokeColor={"#ff0472"}
              />
            );
          }
        },
      },
    ];

    return (
      <div>
        <Button type="primary"><Link to="/topics">Back to Home Page</Link></Button>
        <Title level={4} style={{ marginBottom: 24 }}>
          {topic.description}
        </Title>
        <ul
          style={{
            paddingInlineStart: 12,
            fontSize: "0.8rem",
            marginBottom: 24
          }}
        >
          <li>Questionnaire Set: {topic.qset_text}</li>
          <li>Label: {topic.label}</li>
          <li>Completion Status: {pg}</li>
        </ul>
        <Table
          columns={ddCols}
          dataSource={dd}
          pagination={{ defaultPageSize: 50, hideOnSinglePage: true }}
          size="middle"
          loading={{
            spinning: loading,
            tip: "Loading...",
            wrapperClassName: "table-spin",
          }}
          bordered
        />
      </div>
    );
  } else {
    return (
      <div style={{ padding: "56px 12px" }}>
        <Title level={5} style={{ marginBottom: 24 }}>
          <WarningFilled style={{ color: "#DC4A3F" }} />
          This questionnaire does not exist! Please try again!
        </Title>
      </div>
    );
  }
}

export default DDPage;
