import React, { useState, useEffect } from "react";
import { client } from "../services/menu.service";
import { Navigate, Outlet, Link, useLocation } from "react-router-dom";

import { Typography } from "antd";
import { Table, Progress, Input, Space, Button } from "antd";
import type { TableProps, InputRef, TableColumnType } from "antd";
import { SearchOutlined } from "@ant-design/icons";

const { Title, Text } = Typography;

interface DataType {
  description: string;
  eid: string;
  categories: string;
  completed_pert: number;
  unit: string;
}

type DataIndex = keyof DataType;

function Monitor() {
  const [lastupdate, setLastupdate] = useState("0000-00-00 00:00:00");
  const [selfharm, setSelfharm] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentUser, setCurrentUser] = useState(false);

  useEffect(() => {
    client.get("/last_updated").then(function (res) {
      setLastupdate(res.data[0].last_updated);
    });

    client.get("/selfharm").then(function (res) {
      setSelfharm(res.data);
      setLoading(false);
    });
  }, []);

  const mCols: TableProps<DataType>["columns"] = [
    {
      title: "iAdoreID",
      dataIndex: "subjectid",
      key: "subjectid",
      align: "left",
      fixed: "left",
      width: 100,
    },
    {
      title: "Gender",
      dataIndex: "gender",
      key: "gender",
      align: "center",
      width: 100
    },
    {
      title: "Submission Status",
      dataIndex: "submitted",
      key: "submitted",
      align: "left",
      render: (value) => (value === 1 ? "Submitted" : "In progress"),
      width: 100,
    },
    {
      title: "YSR - I deliberately try to hurt or kill myself",
      dataIndex: "q353",
      key: "q353",
      align: "left",
      width: 100,
    },
    {
      title: "YSR - I think about killing myself",
      dataIndex: "q446",
      key: "q446",
      align: "left",
      width: 100,
    },
    {
      title: "YPCORE - I've thought of hurting myself",
      dataIndex: "q494",
      key: "q494",
      align: "left",
      width: 100,
    },
    {
      title: "CDI - I think about killing myself",
      dataIndex: "q898",
      key: "q898",
      align: "left",
      width: 100,
    }
  ];

  return (
    <div>
      <Title level={2} style={{ marginBottom: 2 }}>
        Suicide & Self-harm Monitoring
      </Title>
      <Text style={{ color: "#808080", marginBottom: 8 }}>Last Updated: {lastupdate}</Text>
      <br/>
      <a href={"/api/v1/export-sh"}><Button type="primary">Export Data</Button></a>
      <Table
        style={{ marginTop: 24 }}
        columns={mCols}
        dataSource={selfharm}
        pagination={{ defaultPageSize: 50, hideOnSinglePage: true }}
        scroll={{ x: "100%" }}
        size="middle"
        loading={{
          spinning: loading,
          tip: "Loading...",
          wrapperClassName: "table-spin",
        }}
        bordered
      />
    </div>
  );
}

export default Monitor;
