import React from "react";
import { Typography } from "antd";
const { Title} = Typography;

function Notfound () {
    return (
        <div>
            <Title level={2}>Not Found</Title>
            <Title level={5}>The page you are looking for does not exist.</Title>
        </div>
    )
}
export default Notfound;