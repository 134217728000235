import Login from "../auth/login";

const publicRoutes = [
  {
    path: "/login",
    element: <Login />,
    exact: true,
  },
];

export default publicRoutes;