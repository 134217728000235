import React, { useState, useEffect } from "react";
import { client } from "./services/menu.service";
import { Navigate, Outlet, Link, useLocation } from "react-router-dom";

import logo from "./iadore.png";

import { Dropdown, MenuProps, Button, Layout, Menu, Avatar, Flex } from "antd";
import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  DatabaseOutlined,
  DashboardOutlined,
  AlertOutlined,
  DownloadOutlined,
} from "@ant-design/icons";

const { Header, Sider, Content } = Layout;

function Home() {
  const [collapsed, setCollapsed] = useState(false);
  const [currentUser, setCurrentUser] = useState(true);
  const [avatarURL, setAvatarURL] = useState(
    "https://api.dicebear.com/7.x/miniavs/svg?seed=45"
  );
  const [currentLogout, setCurrentLogout] = useState(false);
  const imgWidth = collapsed ? "7vmin" : "12vmin";
  const pathname = useLocation().pathname;

  const menuActiveItem = pathname.includes("seta")
    ? "seta"
    : pathname.includes("setb")
    ? "setb"
    : pathname.includes("progress")
    ? "progress"
    : pathname.includes("harm")
    ? "monitor"
    : "topics";

  const clickLogout = () => {
    client
      .post("/rest-auth/logout/", { withCredentials: true })
      .then(function (res) {
        localStorage.removeItem("token");
        setCurrentLogout(true);
      });
  };

  useEffect(() => {
    client
      .get("/rest-auth/user")
      .then(function (res) {
        if (res.data.username) {
          setCurrentUser(res.data.first_name);
          client.get(`/users?id=${res.data.username}`).then(function (res2) {
            setAvatarURL(res2.data[0].src);
          });
        }
      })
      .catch(function (error: any) {
        setCurrentUser(false);
      });
  }, []);

  const items: MenuProps["items"] = [
    {
      key: "changepw",
      label: (
        <Button type="link">
          <a href="/changepassword" rel="noopener noreferrer">
            Change Password
          </a>
        </Button>
      ),
    },
    {
      key: "logout",
      label: (
        <Button type="link" onClick={clickLogout}>
          Log Out
        </Button>
      ),
    },
  ];

  if (localStorage.getItem("token") && currentUser) {
    return (
      <Layout style={{ width: "100%" }}>
        <Sider
          trigger={null}
          collapsible
          collapsed={collapsed}
          collapsedWidth={70}
          width={250}
          style={{
            overflow: "auto",
            height: "100vh",
            paddingTop: "2vh",
            backgroundColor: "#fff",
            borderInlineEnd: "1px solid rgba(5, 5, 5, 0.06)",
            position: "fixed",
            zIndex: 2,
          }}
        >
          <center>
            <img
              src={logo}
              className="Home-logo"
              alt="logo"
              style={{
                width: imgWidth,
              }}
            />
          </center>
          <Menu
            theme="light"
            mode="inline"
            style={{
              borderInlineEnd: "none",
            }}
            defaultSelectedKeys={[menuActiveItem]}
            selectedKeys={[menuActiveItem]}
            defaultOpenKeys={["progress"]}
            items={[
              {
                key: "topics",
                label: <Link to={"/topics"}>Data Catalogues</Link>,
                icon: <DatabaseOutlined />,
              },
              {
                key: "progress",
                label: "Survey Progress",
                icon: <DashboardOutlined />,
                children: [
                  {
                    key: "overview",
                    label: <Link to={"/progress/overview"}>Overview</Link>,
                  },
                  {
                    key: "seta",
                    label: <Link to={"/progress/seta"}>Set A</Link>,
                  },
                  {
                    key: "setb",
                    label: <Link to={"/progress/setb"}>Set B</Link>,
                  },
                ],
              },
              {
                key: "monitor",
                label: (
                  <Link to={"/self-harm-monitor"}>
                    Suicide & Self-harm Monitoring
                  </Link>
                ),
                icon: <AlertOutlined />,
              },
              {
                key: "export",
                label: <a href={"/api/v1/export"}>Export Responses</a>,
                icon: <DownloadOutlined />,
              },
            ]}
          />
        </Sider>
        <Layout
          style={{
            marginLeft: collapsed ? 70 : 250,
          }}
        >
          <Header
            style={{
              padding: "0px 8px 0px 0px",
              background: "#fff",
              height: "42px",
              lineHeight: "42px",
              zIndex: 1,
              position: "fixed",
              width: collapsed ? "calc(100% - 70px)" : "calc(100% - 250px)",
            }}
          >
            <Flex justify="space-between" align="center">
              <Button
                type="text"
                icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
                onClick={() => setCollapsed(!collapsed)}
                style={{
                  fontSize: "16px",
                  width: 36,
                  height: 36,
                }}
              />
              <Dropdown menu={{ items }}>
                <span>
                  <Avatar
                    src={avatarURL}
                    style={{
                      boxShadow: "0 0 0 1px #1f232826",
                      marginRight: 6,
                    }}
                  />{" "}
                  Logged in as {currentUser}
                </span>
              </Dropdown>
            </Flex>
          </Header>
          <Content
            style={{
              backgroundColor: "#fff",
              padding: "48px 36px",
            }}
          >
            <Outlet />
          </Content>
        </Layout>
      </Layout>
    );
  } else {
    return <Navigate to="/login" />;
  }
}

export default Home;
