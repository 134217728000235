import React, { useState, useEffect } from "react";
import { client } from "../services/menu.service";

import { Navigate } from "react-router-dom";

import logo from "../iadore.png";
import { Button, Form, Input, Divider } from "antd";
import { Flex, FormProps, message } from "antd";
import { Col, Row } from "antd";

type FieldType = {
  username?: string;
  password?: string;
};

function Login() {
  const [currentUser, setCurrentUser] = useState(false);

  const onFinish: FormProps<FieldType>["onFinish"] = (values:any) => {
    client
      .post("/rest-auth/login/", values)
      .then(function (res:any) {
        localStorage.setItem("token", res.data.key);
        setCurrentUser(true);
      })
      .catch(function (error:any) {
        message.error("Incorrect username/ password. Please try again!");
      });
  };

  if (currentUser) {
    return <Navigate to="/" />;
  } else {
    return (
      <div>
        <Row>
          <Col>
            <Flex
              align="center"
              style={{
                marginBottom: "20px",
              }}
            >
              <img src={logo} className="App-logo" alt="logo" />
              <Divider
                type="vertical"
                style={{ height: "3rem", marginInline: 10 }}
              />
              <span style={{ fontSize: "1rem", fontWeight: 500 }}>
                Data Management Platform
              </span>
            </Flex>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form
              name="basic"
              labelAlign="left"
              labelCol={{ span: 8 }}
              wrapperCol={{ span: 16 }}
              initialValues={{ remember: true }}
              onFinish={onFinish}
              // onFinishFailed={onFinishFailed}
              autoComplete="off"
              style={{
                width: 335,
              }}
            >
              <Form.Item<FieldType>
                label="Username"
                name="username"
                style={{ marginBottom: 10 }}
                rules={[
                  { required: true, message: "Please enter your username!" },
                ]}
              >
                <Input />
              </Form.Item>

              <Form.Item<FieldType>
                label="Password"
                name="password"
                rules={[
                  { required: true, message: "Please enter your password!" },
                ]}
              >
                <Input.Password />
              </Form.Item>
              <Form.Item wrapperCol={{ span: 24 }}>
                <Flex justify="end">
                  <Button type="primary" htmlType="submit">
                    Sign In
                  </Button>
                </Flex>
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </div>
    );
  }
}

export default Login;
