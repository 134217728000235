import axios from "axios";

axios.defaults.xsrfCookieName = "csrftoken";
axios.defaults.xsrfHeaderName = "X-CSRFToken";
axios.defaults.withCredentials = true;

export const baseURL = process.env.REACT_APP_API_BASE;
export const headers = {
  "Content-type": "application/json",
};

export const client = axios.create({
  baseURL: baseURL
});