import { title } from "process";
import React, { useEffect, useState, useRef } from "react";
import { useParams, Navigate } from "react-router-dom";
import { client } from "../services/menu.service";

import type { TableProps, InputRef, TableColumnType } from "antd";
import type { FilterDropdownProps } from "antd/es/table/interface";

import { Typography } from "antd";
import { Table, Input, Space, Button, Flex } from "antd";

import {
  SearchOutlined,
  CloseCircleFilled,
  CheckCircleFilled,
  WarningFilled
} from "@ant-design/icons";

const { Title, Text } = Typography;

interface DataType {
  key: string;
  name: string;
  tags: string[];
  code: string;
  qset: number;
  label: string;
  subjectid: string;
  inprogress: string;
}

type DataIndex = keyof DataType;

function Completion() {
  const set = useParams().set;
  const title =
    set === "seta" ? "Set A" : set === "setb" ? "Set B" : "Overview";
  const qset = set === "seta" ? 0 : set === "setb" ? 1 : 3;

  const [data, setData] = useState([]);
  const [cols, setCols] = useState([]);
  const [loading, setLoading] = useState(true);
  const [subjectcount, setSubjectcount] = useState(0);
  const [completecount, setCompletecount] = useState(0);
  const [lastupdate, setLastupdate] = useState("0000-00-00 00:00:00");

  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const [current, setCurrentUser] = useState(false);
  const searchInput = useRef<InputRef>(null);

  useEffect(() => {
    client.get("/last_updated").then(function (res) {
      setLastupdate(res.data[0].last_updated);
    });
    client
      .get(`/qset_count?id=${qset}`)
      .then((res) => {
        setSubjectcount(res.data[0].subject_count);
        setCompletecount(res.data[0].completed_count);
        return res.data[0].cols;
      })
      .then(function (res) {
        setCols(res);
        client
          .get(`/subject_progress?query={${res.map((d: any) => d.code)}}`)
          .then(function (res) {
            setData(res.data);
          });
      })
      .catch(function (error) {});
    setLoading(false);
  }, [qset]);

  const handleReset = (clearFilters: () => void) => {
    clearFilters();
    setSearchText("");
  };

  const handleSearch = (
    selectedKeys: string[],
    confirm: FilterDropdownProps["confirm"],
    dataIndex: DataIndex
  ) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const getColumnSearchProps = (
    dataIndex: DataIndex,
    text: string
  ): TableColumnType<DataType> => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            handleSearch(selectedKeys as string[], confirm, dataIndex)
          }
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() =>
              handleSearch(selectedKeys as string[], confirm, dataIndex)
            }
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: boolean) => (
      <SearchOutlined style={{ color: filtered ? "#1677ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes((value as string).toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
  });

  const qCols: TableProps<DataType>["columns"] = cols.map(function (d: any) {
    if (d.code === "subjectid") {
      return {
        title: d.label,
        key: d.code,
        dataIndex: d.code,
        align: "left",
        fixed: "left",
        width: 100,
        ...getColumnSearchProps(d.code, "iAdore Subject IDs"),
      };
    } else if (d.code === "inprogress") {
      return {
        title: d.label,
        key: d.code,
        dataIndex: d.code,
        align: "left",
        filters: [
          {
            text: "Set A",
            value: "Set A",
          },
          {
            text: "Set B",
            value: "Set B",
          },
        ],
        onFilter: (value, record) =>
          record.inprogress.toString().includes(value as string),
      };
    } else if (d.code === "gender") {
      return {
        title: d.label,
        key: d.code,
        dataIndex: d.code,
        align: "center",
        width: 100,
        filters: [
          {
            text: "Female",
            value: "Female",
          },
          {
            text: "Male",
            value: "Male",
          },
        ],
        onFilter: (value, record: any) => record[d.code] === value
      };
    } else if (d.code === "seta_count" || d.code === "setb_count") {
      return {
        title: d.label,
        key: d.code,
        dataIndex: d.code,
        align: "center"
      };
    } else if (d.code.includes("duration") || d.code.includes('date')) {
      return {
        title: d.label,
        key: d.code,
        dataIndex: d.code,
        align: "center",
        sorter: (a:any, b:any) => d.code.includes("duration") ? a[d.code] - b[d.code] : a[d.code].localeCompare(b[d.code]),
        width: d.code.includes("duration") ? 88 : 120
      }
    } else if (d.code === "seta" || d.code === "setb") {
      return {
        title: d.label,
        key: d.code,
        dataIndex: d.code,
        align: "center",
        width: 82,
        fixed: "left",
        render: function (value) {
          if (value === 0) {
            return <CloseCircleFilled style={{ color: "#f4675c" }} />;
          } else if (value == 1) {
            return <CheckCircleFilled style={{ color: "#30d496" }} />;
          } else if (value == 2) {
            return <CheckCircleFilled style={{ color: "#ff9800" }} />;
          } else {
            return <WarningFilled style={{color: "#9e9e9e"}} />
          }
        },
        filters: [
          {
            text: <span><CheckCircleFilled style={{ color: "#30d496", marginRight: 4 }} />Completed</span>,
            value: 1,
          },
          {
            text: <span><CheckCircleFilled style={{ color: "#ff9800", marginRight: 4 }} />Completed SAQs Required</span> ,
            value: 2,
          },
          {
            text: <span><CloseCircleFilled style={{ color: "#f4675c", marginRight: 4 }} />Not Completed</span> ,
            value: 0,
          },
          {
            text: <span><WarningFilled style={{color: "#9e9e9e", marginRight: 4}} />Not Submitted</span>,
            value: 3
          },
        ],
        onFilter: (value, record: any) => record[d.code] === value,
      };
    } else {
      return {
        title: d.label,
        key: d.code,
        dataIndex: d.code,
        align: "center",
        width: 82,
        render: function (value) {
          if (value === 0) {
            return <CloseCircleFilled style={{ color: "#f4675c" }} />;
          } else {
            return <CheckCircleFilled style={{ color: "#30d496" }} />;
          }
        },
      };
    }
  });

  return (
    <div>
      <Title level={2} style={{ marginBottom: 2 }}>
        Completion Status - {title}
      </Title>
      <Text style={{ color: "#808080" }}>Last Updated: {lastupdate}</Text>
      <Title level={5} style={{ marginBottom: 24 }}>
        <strong>Number of Subjects: </strong>
        {subjectcount} (Completed: {completecount})
      </Title>
      <Table
        columns={qCols}
        dataSource={data}
        pagination={{ defaultPageSize: 15, hideOnSinglePage: true }}
        scroll={{ x: qset < 3 ? "100%" : "unset" }}
        size="middle"
        loading={{
          spinning: loading,
          tip: "Loading...",
          wrapperClassName: "table-spin",
        }}
      />
    </div>
  );
}

export default Completion;
